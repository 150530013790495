body {
  margin: 0;
  font-family: "Proxima Nova", "Open Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.content {
  padding: 20px;
}

.header {
  padding: 10px;
  border-bottom: 1px solid #999;
}
.header a {
  color: #0072ff;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 5px;
}
.header a:hover {
  color: #8a0f53;
}
.header small {
  color: #666;
}
.header .active {
  color: #2c7613;
}

/* Button */
.button--primary {
  background: #008864;
  border: 2px solid #008864;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 200ms all linear;
  -o-transition: 200ms all linear;
  transition: 200ms all linear;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 0;
  padding: 10px;
}

/* Inputs/controls */
:focus-visible {
  outline: #008864 auto 1px;
  border-radius: 1px;
}

/* Typography */
h1 {
  font-size: 1.6rem;
}