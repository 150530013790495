.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login__page input:not(.button--primary) {
    height: 36px;
    margin-bottom: 10px;
    background: #ffffff;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 0 9px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
}

html, body, #root, #root > div, .App, .page {
    height: 100%;
}

.login__page {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.login__form {
    width: 260px;
    margin: auto;
}

.login__page .button--primary {
    min-width: 100%;
}

.login__page img {
    text-align: center;
    margin: 0 auto 10px;
    width: 200px;
    display: block;
}