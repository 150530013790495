.product-items {
    flex: 1 1 100%;
    border-bottom: 1px solid black;
    padding: 20px 0px;
}
.product-outer-wrap {
    flex-flow: row wrap;
    text-align: left;
}

.item-image,.item-desc, .item-action{
    float:left;
}
.hide {
    display:none !important;
}
.show {
    display: block;
}

.bg-red{
    background:#d47f7f !important;
    color: white !important;
    font-weight: bold;
}

.bg-green{
    background:#5f9466 !important;
    color: white !important;
    font-weight: bold;
}

.bg-amber{
    background:#c5a546  !important;
    color: white !important;
    font-weight: bold;
}

.cat-title{
    font-weight: bold !important;
}

.topBanner{
    display: flex;
}
.topBanner .item-desc{
    width:70%;
    padding:0px 20px;
}

.topBanner .item-picking{
    width:30%;
}

.item-name{
    font-size: 1.3rem;
    font-weight: 400;
}
.item-sku{
    font-size: 0.7rem;
}

.details .item-desc{
    width:60%;
    padding:0px 20px;
}

.item-qty-ordered,.item-price,.item-unit,.item-note{
    font-size: 0.8rem;
    font-weight: bold;
}

.item-note{
    color: #166e26;
}

.item-unit {
    color : #d15252;
}

.inc{
    vertical-align: -0.23em;
}

.item-qty{
    margin-top:10px;
}
.qty-input{
    width: 30px;
    border: 0px;
    text-align: center;
    font-size: 18px;
    margin: 0 5px;
    height: 24px;
    box-sizing: border-box;
}

.add-minus-quantity {
    display: flex;
    align-items: center;
}
.item-qty .weighted-quantity {
    height: 58px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: -15px;
    max-width: 100px;
    font-weight: 600;
}
.item-qty .weighted-quantity .weight-input {
    margin-top: 5px;
    height: 100%;
    font-size: 22px;
    border: 2px solid green;
}

.svg-inline--fa{
    cursor: pointer;
}

.item-image .thumb{
    width:50px
}

.item-image .main{
    width:200px
}
.red{
    color: red;
}
.green{
    color: green;
}
.review{
    display:block;
}

.subs {
    width: 100%;
    clear: both;
}

.item-info .details.show .item-action {
    text-align: center;
}

.item-info .details.show > div {
    display: flex;
}

.item-info .details.show .item-action {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.item-info .details.show .item-action > div:first-of-type {
    margin-bottom: 12px;
}

.item-image {
    width: 31%;
    text-align: center;
}
.item-image img.main {
    width: auto;
    max-width: 100%;
    max-height: 140px;
}
.title__subs{
    margin-bottom: 10px;
    font-size: 12px;
    text-align: left;
}

/**/
.product-outer-wrap .item-info .details.show > div {
    flex-direction: column;
}
.product-outer-wrap .item-desc {
    width: 73%;
}
.product-outer-wrap .item-desc .fa-2x {
    font-size: 3.5em;
}
.product-outer-wrap .details .item-desc {
    padding: 0 30px 0 10px;
    position: relative;
}
.product-outer-wrap .item-image {
    width: 100%;
}
.product-outer-wrap .item-qty {
    margin-top: 20px;
    float: left;
}
.product-outer-wrap .qty-input {
    width: 47px;
    font-size: 24px;
    height: 46px;
}
.product-outer-wrap .scan {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
}
.product-outer-wrap .product-items .details.show svg {
    font-size: 3.5em;
}
.product-outer-wrap .details .item-content {
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
}
.product-outer-wrap .item-desc .MuiAlert-filledSuccess {
    position: absolute;
    top: 0px;
    bottom: 0px;
    height: 35px;
    align-items: center;
    padding: 5px 10px;
    width: 141px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: -30px;
    text-align: center;
}
.product-outer-wrap .item-desc .MuiAlert-filledSuccess .MuiAlert-icon {
    padding: 0;
}
.product-outer-wrap .item-desc .MuiAlert-filledSuccess .MuiAlert-icon svg {
    font-size: 2rem;
}
.item-info .topBanner {
    width: 100%;
}
.subs .ddf {
    flex-direction: column;
    margin: 10px 0 10px 10px;
}
.product-outer-wrap .subs .ddf svg.fa-level-up-alt.fa-2x {
    font-size: 2.5em;
}
.product-outer-wrap .subs .ddf svg.svg-inline--fa.fa-trash.fa-2x {
    font-size: 3.2em;
}
.product-outer-wrap .subs .ddf .item-desc {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 0 0 0;
}
.product-outer-wrap .subs .ddf .item-action {
    position: absolute;
    bottom: -10px;
    right: 20px;
}
.product-outer-wrap .subs .ddf .item-desc .add-minus-quantity {
    margin: 10px 10px 0 25px;
}
.product-outer-wrap .subs .ddf .item-desc .scan {
    max-width: 65px;
    position: absolute;
    left: -35px;
    margin: 0;
    bottom: 0;
}
.product-outer-wrap .subs .ddf .item-desc .MuiAlert-filledSuccess {
    right: 55px;
    top: 15px;
}
.item-desc .item-qty-ordered {
    display: block;
}
#picking-actions svg,
.alternate-actions svg {
    font-size: 3em;
}