.cat-title.MuiTypography-body1 {
    font-weight: 600 !important;
    text-align: left;
    line-height: 1.3;
    font-size: 15px;
}

.order-table > .MuiButtonBase-root {
    padding: 10px;
}

.order-table .MuiAccordionSummary-content {
    margin: 0 !important;
}

.order-table .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
}

.order-table.MuiAccordion-root {
    margin: 0 0 10px !important;
}

.MuiAccordionDetails-root.product-outer-wrap {
    padding: 10px;
}

.item-info .topBanner{
    cursor:pointer;
}

.item-info .topBanner .item-desc {
    padding: 0;
}
.MuiAccordionDetails-root .MuiTypography-body1 {
    padding: 0;
    font-size: 13px;
}

span.item-name {
    font-size: 14px;
    font-weight: 600;
}

.item-info .topBanner .item-desc {
    width: 64%;
}

.item-info .topBanner {
    justify-content: space-between;
    align-items: center;
}

.item-picking > .red,
.item-picking > .green {
    display: flex;
    flex-direction: column;
}

.picked-label {
    color: #000000;
}

.item-info .topBanner .item-picking {
    width: 24%;
}
.item-info .topBanner .item-picking .status {
    font-size: 10px;
    margin-bottom: 5px;
}

.item-info .item-action {
    width: 9%;
}

.item-info .item-action svg {
    font-size: 25px;
}

.product-outer-wrap .product-items {
    border-bottom: 0;
}

.cat-title > span {
    display: inline-block;
    width: 100%;
    font-size: 14px;
}

.MuiAccordion-rounded {
    border-radius: 0 !important;
}

.order-table .MuiIconButton-root {
    color: rgb(255 255 255) !important;
}

.product-outer-wrap .product-items:not(:first-of-type) {
    border-top: 1px solid #d2d2d2;
    margin-top: 10px;
    padding-top: 10px;
}

#order-items {
    padding-bottom: 10px;
}