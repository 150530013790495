.substitute-item .item-info {
    display: flex;
    text-align: left;
    border-bottom: 1px solid #dcdcdc;
    padding: 10px 0;
}
.substitute-item .item-info:last-of-type {
    border-bottom: 0;
}

.substitute-item .item-desc{
    width:70%;
    padding:0px 20px;
}
.substitute-actions .action{
    display:inline-block;
    width: 50%;
}
.substitute-actions{
    margin-top:10%;
}

.substitute-actions svg {
    font-size: 5em;
    margin-bottom: 5px;
}

.substitute-actions .action span {
    font-size: 14px;
}

.substitute-item span.item-name {
    font-size: 16px;
}
.substitute-item span.item-sku {
    margin-bottom: 5px;
    display: inline-flex;
}

.substitute-item > span.close {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-bottom: 8px;
    padding: 8px 10px;
    border: 2px solid #008863;
    color: #ffffff;
    background: #008863;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none !important;
}

.alternate-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 15px;
}
.alternate-actions .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
}
.alternate-actions .action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.alternate-actions label {
    display: block;
    font-size: 13px;
    margin-bottom: 2px;
}

/* Search */
.sub-search {
    text-align: left;
    margin: 25px 0;
    padding: 10px 0 20px;
}
.sub-search .search > label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
}
.sub-search input {
    display: block;
    height: 34px;
    width: 100%;
    margin-bottom: 5px;
    padding: 10px;
    box-sizing: border-box;
}
.substitute-item .sub-search .item-info {
    padding: 10px 0;
}
.add-action {
    text-align: center;
}
.add-action label {
    font-size: 13px;
}

.subs .ddf {
    display: flex;
    padding-left: 35px;
    margin: 15px 0 10px auto;
    position: relative;
}
.subs .ddf > svg {
    transform: rotate(90deg);
    position: absolute;
    left: 10px;
}
.subs .ddf .item-desc {
    padding: 0 10px;
}

.alternate-scan.fade{
    color: grey;
}

/* Move col over so scan button fits on subs */
.subs .item-qty .weighted-quantity {
    margin: 0; padding: 0 0 5px 25px;
}