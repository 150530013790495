#order-comments {
    text-align: left;
    background: #ececec;
    padding: 10px;
    font-weight: 600;
    font-size: 15px;
    margin: 15px 0 25px;
}

.order-comments__inner {
    font-size: 13px;
    font-weight: 400;
    margin-top: 5px;
}