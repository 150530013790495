#picking-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#picking-actions .actions,
#review .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
}

#review .actions div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#picking-actions label,
#review label {
    display: block;
    cursor: pointer;
    font-size: 13px;
    margin-bottom: 2px;
}

#picking-actions div.park {
    margin-right: 10px;
}

.react-confirm-alert-body {
    width: auto;
    margin: 20px;
}

.scan.fade{
    color: grey;
}
.scan {
    margin-top: -15px;
}

.hideScannerInput{
    opacity:0;
}