.customer-Info .details {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 20px;
    font-size: 14px;
}

.customer-Info .details > span {
    margin-bottom: 2px;
}