.review-notes {
    margin: 15px 0 20px;
}

.review-notes .notes {
    text-align: left;
    background: #ececec;
    padding: 10px;
    font-weight: 600;
    font-size: 15px;
    margin: 15px 0 25px;
}

.review-notes .content{
    padding-left:0px;
}

.summary__table {
    list-style: none;
    text-align: left;
    padding: 10px;
    margin: 0;
    font-size: 14px;
}
.summary__table:nth-of-type(odd) {
    background: #ececec;
}

.summary__table strong {
    min-width: 133px;
    display: inline-block;
}

.summary__table li:not(:last-of-type) {
    margin-bottom: 5px;
}
#review {
    text-align: left;
}

.summary__title {
    font-size: 16px;
}

#review table tbody tr:nth-of-type(even) {
    background: #ececec;
}

#review table td {
    padding: 5px;
}
#review table th {
    padding: 5px;
}

#review table  {
    font-size: 12px;
    border-collapse: collapse;
}

#review thead {
    background: #cccccc;
}

.review-notes .content {
    padding: 0;
    font-weight: 400;
}