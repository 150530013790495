#final_review {
    text-align: left;
}

#final_review table tbody tr:nth-of-type(even) {
    background: #ececec;
}

#final_review table td {
    padding: 5px;
}
#final_review table th {
    padding: 5px;
}

#final_review table  {
    font-size: 12px;
    border-collapse: collapse;
}

#final_review thead {
    background: #cccccc;
}

#final_review .actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
}

#final_review .actions div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#final_review .actions label
{
    display: block;
    font-size: 13px;
    margin-bottom: 2px;
}
.errorMessage{
    color:red;
    font-size: 14px;
}
