.add-additional-products > span.close {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-bottom: 8px;
    padding: 8px 10px;
    border: 2px solid #008863;
    color: #ffffff;
    background: #008863;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none !important;
}
.add-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 15px;
}
.add-actions .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
}
.add-actions .action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.add-actions label {
    display: block;
    font-size: 13px;
    margin-bottom: 2px;
}

.add-scan.fade{
    color: grey;
}