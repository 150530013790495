.MuiTable-root .MuiTableCell-root {
    padding: 10px 5px;
    /*white-space: nowrap;*/
}

.MuiFormControl-root {
    padding: 0 !important;
    min-width: 100% !important;
}

.MuiTableCell-root {
    font-size: 10px !important;
    word-break: break-all !important;
}
.MuiTableCell-root .OrderName{
    font-size: 12px !important;
    font-weight: bold;
}

th.MuiTableCell-root {
    word-break: normal !important;
    line-height: 1.1;
}
th.MuiTableCell-root:last-of-type {
    text-align: center;
}

.MuiTableRow-root td:last-of-type {
    text-align: center !important;
}

.MuiToolbar-regular {
    padding: 0 !important;
}

.MuiTablePagination-toolbar .MuiTablePagination-spacer,
.MuiTablePagination-toolbar .MuiTablePagination-selectRoot,
.MuiTablePagination-toolbar .MuiTablePagination-spacer {
    /*display: none !important;*/
}

.MuiTablePagination-toolbar > div {
    width: 100%;
    margin: 0;
    text-align: center;
}

.MuiToolbar-regular {
    min-height: 0;
}
.MuiToolbar-regular .MuiInputBase-formControl {
    padding: 10px;
}

.MuiTableSortLabel-root {
    font-weight: 800;
}

.MuiTable-root .MuiTableCell-root {
    text-align: left;
    letter-spacing: 0;
}

.MuiTableSortLabel-root {
    flex-direction: row;
}
.MuiTableSortLabel-root .MuiTableSortLabel-icon {
    display: none !important;
}

.MuiLink-underlineHover {
    text-decoration: underline !important;
}

.orders__wrapper {
    max-width: 100%;
    padding-bottom: 20px;
}

.MuiInput-underline:after {
    border-color: #008864 !important;
}

.OrderName {
    display: flex;
    flex-direction: column;
}

input.MuiInputBase-input {
    font-size: 14px;
}