.header__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    background: #ececec;
    padding: 10px;
    margin: -10px -10px 15px -10px;
    border-bottom: 1px solid #cccccc;
}

.header .content {
    padding: 0;
}

.header {
    padding: 0;
    border: 0;
}

h1.--has-tagline {
    margin-top: 25px;
    margin-bottom: 5px;
}
.header__tagline {
    margin: 0 0 25px;
    font-size: 13px;
}

.button--secondary.MuiLink-underlineHover {
    box-sizing: border-box;
    padding: 3px 10px;
    border: 2px solid #008863;
    color: #008863;
    background: #ffffff;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none !important;
}