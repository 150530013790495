.cat-wrapper{
    border: 2px solid black;
    padding:20px;
    margin-bottom:15px;
    font-size: 14px;
}
.cat-wrapper .icon{
    float:left;
}
.black{
    color:black !important;
}
.categoryLabel{
    cursor:pointer;
}

.title__section {
    display: flex;
    flex-wrap: wrap;
}

.title__inner {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 10px;
    width: 100%;
    justify-content: space-between;
}

.title__inner > p {
    font-size: 22px;
    margin: 0;
}

.title__inner > a {
    margin-left: 10px;
}
.unlocked {
    cursor:pointer;
}